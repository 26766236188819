import { h, Component } from 'preact';
import style from './style.css';
import ColorPicker from "../ColorPicker";
import Slider from "../Slider";
import EditToolBar from '../EditToolBar';


const EditPanel = ({ commands, project, editor }) => {

	return (
		<div className={style.frameBox} >
			<EditToolBar commands={commands} editor={editor} />
			<div className={style.lineWeightSlider}>
				<Slider label="Line weight" min="5" max={100} value={editor.selectedLineWeight} onChange={commands.selectLineWeight} />
			</div>
			{editor.tool == "timedDraw" ?
				<div className={style.lineWeightSlider}>< Slider label="Duration" min={1} max={project.root.frames.length} value={editor.timedDrawDuration} onChange={commands.selectTimedDrawDuration} /></div> :
				null

			}
			{editor.tool == "timedDraw" ?
				<div className={style.lineWeightSlider}>< Slider label="Noise Intensity" min={0} max={100} value={editor.timedDrawIntensity} onChange={commands.selectTimedDrawIntensity} /></div> :
				null

			}
			{editor.tool == "timedDraw" ?
				<div className={style.lineWeightSlider}>< Slider label="Noise Smoothness" min={0} max={50} value={editor.timedDrawSmoothness} onChange={commands.selectTimedDrawSmoothness} /></div> :
				null

			}{editor.tool == "timedDraw" ?
				<div className={style.lineWeightSlider}>< Slider label="Line Smoothness" min={0} max={100} value={editor.lineSmoothness} onChange={commands.selectLineSmoothness} /></div> :
				null

			}
			{editor.tool != "eraser" ?
				<div ><ColorPicker onSelectColor={commands.selectColor} onSelectPalette={commands.selectPalette} color={editor.selectedColor} palette={editor.selectedPalette} /></div> :
				null

			}

		</div>
	)
}








export default EditPanel;


