import { h, Component } from 'preact';
import style from './style.css';
import ColorPalettes from './colors';

export  const ColorPicker = ({ onSelectColor, onSelectPalette, color, palette: paletteName }) => {
	var palette = ColorPalettes[paletteName] ? ColorPalettes[paletteName] : ColorPalettes.Pico8;
	return (
		<div className={style.colorPicker}>
			<div>
				<select onChange={e => onSelectPalette(e.target.value)}>
					{
					Object.keys(ColorPalettes).map(element => (
						<option value={element} selected={paletteName==element}>{element}</option>
					))
					}
				</select>
			</div>
			<div className={style.colorSwatch}>
				{palette.map(colorValue => (
					<button
						key={colorValue}
						onClick={() => onSelectColor(colorValue)}
						className={colorValue == color ? style.selected : ""}
						style={{ backgroundColor: colorValue }}
						
					></button>
				))}
			</div>
		</div>


	)
}





export default ColorPicker;


