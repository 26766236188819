import { Component } from 'preact';
import { getCommands } from "../commands";
import { createState } from "../state";
import Header from './header';
import FrameSlider from "./FrameSlider";
import ColorPicker from "./ColorPicker"
import ExportModal from "./ExportModal"
import MainPanel from './MainPanel';
import TabSystem, { TabButton, TabContent } from './Tab';
import Canvas from "./Canvas"

export default class App extends Component {

	constructor() {
		super();
		this.state = createState();
		this.commands = getCommands(this.state, () => this.forceUpdate());
		this.commands.loadProject();
	}




	render(props, state) {
		return (
			<div id="app">

				<Header commands={this.commands} project={state.project} editor={state.editor} />
				<Canvas commands={this.commands} project={state.project} editor={state.editor} />
				<MainPanel commands={this.commands} project={state.project} editor={state.editor} />




				<ExportModal close={this.commands.closeExport} open={state.editor.exportURL} url={state.editor.exportURL} />

				<div id="modalParent"></div>
			</div>
		);
	}
}

