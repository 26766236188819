const shortid = require('shortid');

export function createState() {
    return {
        project: createProject(),
        editor: {
            useOnionSkin: true,
            image: {},
            currentEdit: "",
            history: [],
            historyForward: [],
            selectedColor: "black",
            selectedLineWeight: 10,
            selectedFrame: 0,
            playing: true,
            selectedPalette: "Pico8",
            images: [],
            tool: "brush",
            timedDrawDuration: 1,
            timedDrawIntensity: 10,
            timedDrawSmoothness: 16,
            lineSmoothness: 5
        }
    }
}
export function createProject() {
    return {
        root: createSceneObject(400, 400),
        backgroundColor: "#ccc",
        frameRate: 12,
        backgroundImageId: null,
        name: "Gif"
    }
}

export function createSceneObject(w, h) {
    return {
        frames: [createFrame()],
        resolution: createVector(w, h),
        id: shortid(),
        backgroundColor: "#ccc",
    }
}

export function createVector(x, y) {
    return { x, y };
}


export function createFrame() {
    return {
        imageID: shortid(), //createGraphics(w,h);
        lines: [],
    }
}

export function createLine(x1, y1, x2, y2, group, color, weight) {
    return {
        p1: createVector(x1, y1),
        p2: createVector(x2, y2),
        group: group,
        color: color,
        weight: weight,
    }
}
