import preact from "preact";
import classNames from "classnames"
import style from "./style.css";

export function Slider({ value, min, max, onChange, hideMinMax, label }) {
    const sanitizeInput = e => onChange(parseInt(e.target.value));
    var p = Math.floor((value - min) / (max - min) * 100);
    var p = (value - min) / (max - min);
    var pc = `calc(0.75rem + (${p} * (100% - 1.5rem)))`;
    return (
        <span className={style.slider}>
            {label ? <span className={style.label}>{label}</span> : null}
            {hideMinMax ? null : (<span className={style.min}>{min}</span>)}
            <span className={style.sliderInput}>
                <input type="range" min={min} max={max} value={value} onInput={sanitizeInput}></input>
                <span className={style.valueTab} style={{ left: pc }}>{value}</span>
            </span>

            {hideMinMax ? null : (<span className={style.max}>{max}</span>)}
        </span>

    );
}

export default Slider;