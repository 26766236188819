import { h } from 'preact';
import style from './style.css';


import { MdUndo, MdVisibility, MdVisibilityOff, MdFlipToBack, MdPlayArrow, MdCloudDownload } from 'preact-icons/md';

const OnionSkinButton = ({ active, onClick }) => {
	var child = active
		? <span><MdVisibility /> OnionSkin</span>
		: <span><MdVisibilityOff /> OnionSkin</span>

	return (<button onClick={onClick} >{child}</button>);
};

const Header = ({ commands: { undo, toggleOnionSkin, setBackgroundColor, togglePlayMode, exportGif }, project: { useOnionSkin, isPlaying } }) => (
	<header class={style.header}>
		<h1>Draw me a gif</h1>
	</header>
);






export default Header;