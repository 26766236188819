import preact from 'preact';
import { MdCloudDownload, MdDeleteForever, MdSave } from "preact-icons/md";
import ConfirmPopup, { useConfirmPrompt } from '../ConfirmPopup';
import classNames from "classnames";
import style from './style.css';
import ColorModalButton from "../ColorModalButton";
import Slider from "../Slider";

function ProjectPanel({ commands, project, editor }) {

	var onImageChange = (evt) => {
		var tgt = evt.target || window.event.srcElement,
			files = tgt.files;

		// FileReader support
		if (FileReader && files && files.length) {
			var fr = new FileReader();
			fr.onload = function () {
				console.log("loaded");
				var r = new Image();
				r.href = fr.result;
				commands.setBackgroundImage(fr.result);
			}
			fr.readAsDataURL(files[0]);
		}
	}

	var [confirmDelete, askForConfirm] = useConfirmPrompt(commands.deleteProject);
	return (
		<div>
			<div className={style.inputGroup}>
				<label>Name</label>
				<input onChange={commands.setName} value={project.name}></input>
			</div>
			<div className={style.inputGroup}>
				<label>FrameRate</label>
				<Slider min="1" max="30" value={project.frameRate} onChange={commands.setFrameRate} />
			</div>
			<div className={style.inputGroup}>
				<label>Background Color</label>
				<ColorModalButton
					onSelectColor={commands.setBackgroundColor}
					onSelectPalette={commands.selectPalette}
					color={project.root.backgroundColor}
					palette={editor.selectedPalette}

				/>
			</div>
			<div className={style.inputGroup}>
				<label>Background Image</label>
				<input type="file"
					onChange={onImageChange}

				/>
			</div>
			<div className={style.inputGroup}>
				<span className={style.groupLeft}>

				</span>
				<span className={style.groupRight}>
					<button className={classNames("button danger", style.deleteButton)} onClick={askForConfirm}><MdDeleteForever /> Delete</button>
					<button className="button info" onClick={commands.exportGif}><MdCloudDownload /> Export</button>
				</span>

				<ConfirmPopup confirmPrompt={confirmDelete} >Are you sure you want to delete the curent project</ConfirmPopup>
			</div>
		</div>
	);
}


export default ProjectPanel;