import preact, {createContext} from "preact";
import { createState } from "preact";
import { useState, useContext } from "preact/hooks";
import classNames from "classnames";
import style from "./style.css";
const TabContext = createContext(null);

export function TabSystem({children, defaultTab})  {
    var [currentTab, setCurrentTab] = useState(defaultTab);
    var contextObject = {currentTab, setCurrentTab};
    return (
        <TabContext.Provider value={contextObject}>
            {children}
        </TabContext.Provider>
    )
}

export function TabButton ({children, tabName, classSelected}) {
    var tabState = useContext(TabContext);
    return <button onClick={() => tabState.setCurrentTab(tabName)} 
        className={classNames(style.tabButton, {
            [style.selected]: tabState.currentTab==tabName,
            [classSelected] : tabState.currentTab==tabName,
        })}>{children}</button>
}

export function TabContent ({children, tabName}) {
    var tabState = useContext(TabContext);
    return tabState.currentTab==tabName ? (<div className={style.tabContent}>{children}</div>) : null;
}

export default TabSystem;