import { h, Component } from 'preact';
import style from './style.css';
import ColorPicker from "../ColorPicker";
import Slider from "../Slider";
import classNames from "classnames";
import { MdBrush, MdLayers, MdLayersClear, MdUndo, MdTimelapse } from "preact-icons/md";
import Icon from 'preact-icon-base'

const MdEraser = props => (
	<Icon viewBox="0 0 24 24" {...props}>
		<g><path d="M16.24 3.56l4.95 4.94c.78.79.78 2.05 0 2.84L12 20.53a4.008 4.008 0 0 1-5.66 0L2.81 17c-.78-.79-.78-2.05 0-2.84l10.6-10.6c.79-.78 2.05-.78 2.83 0M4.22 15.58l3.54 3.53c.78.79 2.04.79 2.83 0l3.53-3.53l-4.95-4.95l-4.95 4.95z" /></g>
	</Icon>
)

const ToolButton = ({ onClick, selected, children, ...props }) => <button className={classNames("button", {
	[style.selectedTool]: selected
})} onClick={selected ? null : onClick} disabled={selected} {...props}>{children}</button>

const EditToolBar = ({ commands: { selectTool, undo, toggleOnionSkin }, project, editor: { tool, useOnionSkin } }) => {

	return (
		<div className={style.toolbar} >
			<div className={style.leftGroup}>
				<ToolButton onClick={() => selectTool("eraser")} selected={tool == "eraser"} title="Eraser"><MdEraser /></ToolButton>
				<ToolButton onClick={() => selectTool("brush")} selected={tool == "brush"} title="Brush"><MdBrush /></ToolButton>
				<ToolButton onClick={() => selectTool("timedDraw")} selected={tool == "timedDraw"} title="TimedDraw"><MdTimelapse /></ToolButton>
			</div>
			<div className={style.rightGroup}>
				<ToolButton onClick={undo} title="Undo"><MdUndo /></ToolButton>
				<ToolButton onClick={toggleOnionSkin} title="Onion Skin">{useOnionSkin ? <MdLayers /> : <MdLayersClear />}</ToolButton>
			</div>
		</div >
	)
}








export default EditToolBar;


