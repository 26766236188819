import { h, Component } from 'preact';
import style from './style.css';
import P5 from 'p5';
import shortid from 'shortid';
import { createLine } from '../../state';
import GIF from "./gif.js";

class Canvas extends Component {

	componentDidMount() {
		this.processingContext = (sketch) => {
			let renderingGraphics = null;
			var editor = this.props.editor;
			//var project = this.props.project;
			var commands = this.props.commands;
			var self = this;
			var oldP = { x: 0, y: 0, used: false }

			sketch.setup = () => {
				sketch.createCanvas(400, 400);
				renderingGraphics = sketch.createGraphics(400, 400)
			};

			sketch.draw = () => {
				sketch.background(self.props.project.root.backgroundColor);
				if (self.props.project.root.backgroundImageId && editor.images[self.props.project.root.backgroundImageId]) {
					console.log("oh no");
					sketch.image(editor.images[self.props.project.root.backgroundImageId], 0, 0, sketch.width, sketch.height);
				}
				var frameIndex = editor.playing ? Math.floor(sketch.millis() / (1000 / self.props.project.frameRate)) : editor.selectedFrame;
				drawing(frameIndex);

				if (editor.useOnionSkin && frameIndex > 0) {
					renderOnionSkin(frameIndex);
				}
				renderMainFrame(frameIndex);

				drawCursor();
			};

			function drawCursor() {
				sketch.stroke(editor.selectedColor);
				sketch.strokeWeight(editor.selectedLineWeight);
				sketch.point(sketch.mouseX, sketch.mouseY);
			}

			sketch.mousePressed = () => {
				if (mouseOnCanvas()) {
					editor.currentEdit = shortid();
					editor.history.push(editor.currentEdit);
				}
			}
			sketch.mouseReleased = () => {
				commands.saveProject();
			}

			function drawing(frameIndex) {
				if (sketch.mouseIsPressed && sketch.mouseButton === sketch.LEFT && mouseOnCanvas()) {
					if (editor.tool == "eraser") {
						var frameBackground = self.props.project.root.frames[frameIndex % self.props.project.root.frames.length];
						commands.removeLine(sketch.mouseX, sketch.mouseY, sketch.pmouseX, sketch.pmouseY, frameBackground);
						//commands.updateImage(frameBackground);
					} else if (editor.tool == "timedDraw") {
						if (sketch.dist(oldP.x, oldP.y, sketch.mouseX, sketch.mouseY) > editor.lineSmoothness) {
							var frameBackground = self.props.project.root.frames[frameIndex % self.props.project.root.frames.length];
							var newLine = createLine(sketch.mouseX, sketch.mouseY, oldP.x, oldP.y, editor.currentEdit, editor.selectedColor, editor.selectedLineWeight);
							commands.addTimedLine(newLine, frameIndex % self.props.project.root.frames.length);
							oldP.x = sketch.mouseX;
							oldP.y = sketch.mouseY;
						}

					} else {
						var frameBackground = self.props.project.root.frames[frameIndex % self.props.project.root.frames.length];
						var newLine = createLine(sketch.mouseX, sketch.mouseY, sketch.pmouseX, sketch.pmouseY, editor.currentEdit, editor.selectedColor, editor.selectedLineWeight);
						//frameBackground.lines.push(newLine);
						commands.addLine(frameBackground, newLine);
						//commands.updateImage(frameBackground);
					}

				} else {
					oldP.x = sketch.mouseX;
					oldP.y = sketch.mouseY;
					oldP.used = false;
				}

			}


			function renderFrame(frame, ctx) {
				var a = getImage(frame);
				ctx.image(a, 0, 0);
			}

			function renderMainFrame(frameIndex) {
				renderingGraphics.clear();
				renderFrame(self.props.project.root.frames[frameIndex % self.props.project.root.frames.length], renderingGraphics);
				sketch.image(renderingGraphics, 0, 0);
			}

			function renderOnionSkin(frameIndex) {
				renderingGraphics.clear();
				renderFrame(self.props.project.root.frames[(frameIndex - 1) % self.props.project.root.frames.length], renderingGraphics);
				sketch.tint(255, 128);
				sketch.image(renderingGraphics, 0, 0);
				sketch.tint(255, 255);
			}

			function getImage(frame) {
				if (editor.images[frame.imageID]) {
					return editor.images[frame.imageID];
				} else {
					var a = sketch.createGraphics(self.props.project.root.resolution.x, self.props.project.root.resolution.y);
					editor.images[frame.imageID] = a;
					commands.updateImage(frame);
					return a;
				}
			}
			function mouseOnCanvas() {
				return sketch.mouseX > 0 && sketch.mouseX < self.props.project.root.resolution.x && sketch.mouseY > 0 && sketch.mouseY < self.props.project.root.resolution.y;
			}

			function buildGif() {
				var promise = new Promise((s, r) => {
					var gif = new GIF({
						workers: 8,
						quality: 10,
						workerScript: "/assets/gif.worker.js"
					});
					for (var i = 0; i < self.props.project.root.frames.length; i++) {
						renderingGraphics.background(self.props.project.root.backgroundColor || 220);
						if (self.props.project.root.backgroundImageId && editor.images[self.props.project.root.backgroundImageId]) {
							renderingGraphics.image(editor.images[self.props.project.root.backgroundImageId], 0, 0, renderingGraphics.width, renderingGraphics.height);
						}
						renderFrame(self.props.project.root.frames[i % self.props.project.root.frames.length], renderingGraphics);
						// or copy the pixels from a canvas context
						gif.addFrame(renderingGraphics.canvas, {
							copy: true,
							delay: Math.floor(1000 / self.props.project.frameRate)
						});
					}



					gif.on('finished', function (blob) {
						var url = URL.createObjectURL(blob)
						s(url);
					});

					gif.render();
				});
				return promise;
			}

			function loadLargeImage(url) {
				var b = sketch.createGraphics(sketch.width, sketch.height);
				var a = sketch.loadImage(url, img => {
					b.image(a, 0, 0, sketch.width, sketch.height);
				});


				return b;
			}

			editor.buildGif = buildGif;
			editor.createImage = loadLargeImage;
		}
		self.processingInstance = new P5(this.processingContext, this.base);
	}



	shouldComponentUpdate() {
		return false;
	}

	render({ commands: { undo }, project: { useOnionSkin, isPlaying } }) {
		return (
			<div className={style.canvasHolder}></div>
		);
	}

}








export default Canvas;


