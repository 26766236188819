import { createFrame, createProject, createLine } from "./state";
import { checkIntersection } from "line-intersect";
import SimplexNoise from "simplex-noise";
import shortid from "shortid";
import P5 from 'p5';

var simplex = new SimplexNoise()

export function getCommands(state, forceUpdate) {
    var project = state.project;
    var editor = state.editor;
    var commands = {
        addFrame(o) {
            o.frames.push(createFrame());
            editor.selectedFrame = o.frames.length - 1;
            forceUpdate();
        },

        selectFrame(i) {
            editor.selectedFrame = i;
            editor.playing = false;
            forceUpdate();
        },

        selectColor(i) {
            editor.selectedColor = i;
            forceUpdate();
        },

        selectLineWeight(i) {
            editor.selectedLineWeight = i;
            forceUpdate();
        },

        toggleOnionSkin() {
            editor.useOnionSkin = !editor.useOnionSkin;
            forceUpdate();
        },
        togglePlayMode() {
            editor.playing = !editor.playing;
            forceUpdate();
        },

        setBackgroundColorToSelectedColor() {
            project.root.backgroundColor = editor.selectedColor;
            commands.saveProject();
            forceUpdate();
        },
        setBackgroundColor(color) {
            project.root.backgroundColor = color;
            commands.saveProject();
            forceUpdate();
        },
        setBackgroundImage(url) {
            var uid = shortid();
            project.root.backgroundImageId = uid;
            var i = editor.createImage(url);
            editor.images[uid] = i;
            console.log(i);
            commands.saveProject();
            forceUpdate();
        },
        selectPalette(paletteName) {
            editor.selectedPalette = paletteName;
            forceUpdate();
        },
        setFrameRate(v) {
            project.frameRate = Number.isNaN(parseInt(v)) ? 12 : parseInt(v);
            commands.saveProject();
            forceUpdate();
        },
        selectTimedDrawDuration(v) {
            editor.timedDrawDuration = v;
            forceUpdate();
        },
        selectTimedDrawIntensity(v) {
            editor.timedDrawIntensity = v;
            forceUpdate();
        },
        selectTimedDrawSmoothness(v) {
            editor.timedDrawSmoothness = v;
            forceUpdate();
        },
        selectLineSmoothness(v) {
            editor.lineSmoothness = v;
            forceUpdate();
        },
        updateImage(frame) {
            var frameImage = editor.images[frame.imageID]; //getImage(frame.imageID);
            frameImage.clear();
            frame.lines.forEach((line => commands.drawLine(frame, line)));
            //forceUpdate();
        },
        addLine(frame, line) {
            var frameImage = editor.images[frame.imageID]; //getImage(frame.imageID);
            frame.lines.push(line);
            commands.drawLine(frame, line);
        },
        addTimedLine(line, frameIndex) {
            for (let i = 0; i < editor.timedDrawDuration; i++) {
                var cfFrameIndex = (frameIndex + i) % project.root.frames.length;
                var frame = project.root.frames[cfFrameIndex];
                var sx = cfFrameIndex * 21 + 17;
                var sy = cfFrameIndex * 65 + 24;
                var smoothness = editor.timedDrawSmoothness;
                var vx1 = (simplex.noise3D(line.p1.x / smoothness, line.p1.y / smoothness, sx) - 0.5) * 2 * editor.timedDrawIntensity * 0.1;
                var vx2 = (simplex.noise3D(line.p2.x / smoothness, line.p2.y / smoothness, sx) - 0.5) * 2 * editor.timedDrawIntensity * 0.1;
                var vy1 = (simplex.noise3D(line.p1.x / smoothness, line.p1.y / smoothness, sy) - 0.5) * 2 * editor.timedDrawIntensity * 0.1;
                var vy2 = (simplex.noise3D(line.p2.x / smoothness, line.p2.y / smoothness, sy) - 0.5) * 2 * editor.timedDrawIntensity * 0.1;
                var nline = createLine(line.p1.x + vx1, line.p1.y + vy1, line.p2.x + vx2, line.p2.y + vy2, line.group, line.color, line.weight);
                this.addLine(frame, nline);
            }
        },

        removeLine(ax, ay, bx, by, frame) {
            var lineCount = frame.lines.length;
            //frame.lines = frame.lines.filter(line => checkIntersection(line.p1.x, line.p1.y, line.p2.x, line.p2.y, ax, ay, bx, by).type != "intersecting");
            distToSegment
            frame.lines = frame.lines.filter(line => distToSegment(ax, ay, line.p1.x, line.p1.y, line.p2.x, line.p2.y) > (line.weight + editor.selectedLineWeight) * 0.5)
            if (lineCount != frame.lines.length) {
                commands.updateImage(frame);
            }
        },
        drawLine(frame, line) {
            var frameImage = editor.images[frame.imageID]; //getImage(frame.imageID);
            frameImage.stroke(line.color);
            frameImage.strokeWeight(line.weight);
            frameImage.line(line.p1.x, line.p1.y, line.p2.x, line.p2.y);
        },
        deleteProject() {
            state.project = Object.assign(state.project, createProject())
            commands.saveProject();
            forceUpdate();
        },
        undo() {
            if (editor.history.length >= 1) {
                var target = editor.history.pop();
                project.root.frames.forEach(frame => {
                    var initialCount = frame.lines.length;
                    frame.lines = frame.lines.filter(_line => _line.group != target);
                    if (frame.lines.length != initialCount) {
                        commands.updateImage(frame);
                    }
                });
                this.saveProject();
            }
        },

        saveProject() {
            var data = JSON.stringify(project);
            localStorage.setItem('drawGifProject', data);
        },
        loadProject() {
            try {
                var source = localStorage.getItem("drawGifProject");
                if (source) {
                    var data = JSON.parse(source);
                    state.project = Object.assign(state.project, data)
                    commands.saveProject();
                    editor.images = {}
                    //forceUpdate();
                }
            } catch (err) {
                console.logError(err);
            }
        },
        exportGif() {
            console.log("whaaat");
            editor.buildGif()
                .then(_url => {
                    console.log(_url)
                    editor.exportURL = _url;
                    forceUpdate();
                })
                .catch(err => console.log(err));
        },
        closeExport() {
            editor.exportURL = null;
            forceUpdate();
        },
        selectTool(toolname) {
            editor.tool = toolname;
            forceUpdate();
        }
    }

    return commands;
}

function dist(pointX, pointY, x, y) {
    var dx = x - pointX;
    var dy = y - pointY;
    return Math.sqrt(dx * dx + dy * dy);
}
function distToSegment(pointX, pointY, startX, startY, endX, endY) {
    var dx = endX - startX;
    var dy = endY - startY;
    var l2 = dx * dx + dy * dy;

    if (l2 == 0)
        return dist(pointX, pointY, startX, startY);

    var t = ((pointX - startX) * dx + (pointY - startY) * dy) / l2;
    t = Math.max(0, Math.min(1, t));

    return dist(pointX, pointY, startX + t * dx, startY + t * dy);
}