import preact from "preact";
import Modal from "../Modal";
import { useState } from "preact/hooks";
import style from "./style.css"


export function ConfirmPopup({ confirmPrompt, children }) {
    return (
        <Modal open={confirmPrompt.popupOpen} >
            <div className={style.confirmPopup}>
                <main>
                    {children}
                </main>
                <footer>
                    <button className="button" onClick={confirmPrompt.onCancel}>Cancel</button>
                    <button className="button danger"onClick={confirmPrompt.onConfirm}>Confirm</button>
                </footer>
            </div>

        </Modal>
    )
}

export function useConfirmPrompt(callback) {
    var [popupOpen, setOpen] = useState(false);
    return [{
        popupOpen,
        onCancel() {
            setOpen(false)
        },
        onConfirm() {
            setOpen(false);
            callback();
        }
    }, () => setOpen(true)]
}

export default ConfirmPopup