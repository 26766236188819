import { h } from 'preact';
import style from './style.css';
import ColorModal from '../ColorModal';
import { useState } from 'preact/hooks';


export function ColorModalButton(props) {

	var [isOpen, setOpen] = useState(false);
	var close = () => setOpen(false);
	var open = () => setOpen(true);


	return (
		<button className={style.colorButton}
			onClick={open}
			style={{ backgroundColor: props.color }}

		><ColorModal isOpen={isOpen} close={close} {...props} /></button>


	);
}

export default ColorModalButton;