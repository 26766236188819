import { h } from 'preact';
import style from './style.css';
import Modal from "../Modal"

export function ExportModal({close, open, url}) {
	return (<div>
					<Modal open={open}>
						<header>Export</header>
						<div>
							<img src={url}></img>
						</div>
						<footer>
							<button onClick={close}>Close</button>
						</footer>
					</Modal>
				</div>);
}

export default ExportModal;