import { h, Component } from 'preact';
import style from './style.css';
import {MdPlayArrow, MdPause, MdAdd} from "preact-icons/md";
import classNames from "classnames";
import Slider from "../Slider"

const FrameSlider = ({ commands: { selectFrame, addFrame, togglePlayMode }, project: { root }, editor: { selectedFrame,  playing } }, ) => {
	const onInputTextChange = (e) => {
		var v = e.target.value;
		var a = parseInt(v);
		selectFrame(a);
	};
	return (
		<div className={style.frameBox} >
			<button className={classNames(style.button, "button")} onClick={togglePlayMode}>{playing?<MdPause/> : <MdPlayArrow />}</button>

			<div className={style.range}>
				<Slider type="range" min="0" max={root.frames.length - 1} step="1" value={selectedFrame} onChange={selectFrame} disabled={playing} />
				
			</div>

			<button className={classNames(style.button, "button")} onClick={() => addFrame(root)}><MdAdd/></button>
		</div>
	)
}

// <input type="text" value={selectedFrame} onChange={onInputTextChange} disabled={playing}/>






export default FrameSlider;


