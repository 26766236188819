import { h } from 'preact';
import style from './style.css';
import Modal from "../Modal"
import ColorPicker from '../ColorPicker';

export function ColorModal({ isOpen, close, ...props }) {
	return (<div>
		<Modal open={isOpen}>
			<div>
				<header>Color</header>
				<div>
					<ColorPicker {...props} ></ColorPicker>
				</div>
				<footer>
					<button onClick={close}>Close</button>
				</footer>
			</div>


		</Modal>
	</div>);
}



export default ColorModal;