 const Palettes = {
    "Rose": ["#277256", "#a8d889", "#f34481", "#fccbca", "#ffeff3"],
    "Watermelon": ["#c36864", "#ff8482", "#b6c363", "#cee44a", "#f1ffa0"],
    "Sable": ["#daecf6", "#e7b961", "#bd7d33", "#9a5f3f", "#5a2c1d"],
    "Candy": ["#fff7f8", "#ffa69e", "#ff7e6b", "#8c5e58", "#a9f0d1"],
    "Jungle": ["#e2e7d8", "#a7da63", "#614303", "#f58810", "#f24513"],
    "Savana": ["#3b5166", "#fff275", "#ff8c42", "#ff3c38", "#a23e48"],
    "Portal": ["#d5dbeb", "#0082c4", "#005ddf", "#f07716", "#ee7f1b"],
    "Peach": ["#f8f4d7", "#f4dec2", "#f4b36c", "#e98977", "#f2b4a8"],
    "Poppies": ["#ede8e2", "#c7d477", "#4a362a", "#cf1717", "#f97b5f", "#d3d3b2"], 
    "Ocean": ["#e8dbd7", "#d4d8e7", "#d4d8e7", "#46a4c4", "#3066ac", "#c24540"],
    "Strawberry": ["#6bb053", "#185f27", "#ca233d", "#ae192c", "#6e202e"],
    "Pink": ["#f4ebba", "#f4e0b5", "#f0b5b3", "#f6839c", "#fe64a3"],
    "Cobacabana": ["#a44c89", "#ff4b54", "#ff7f42", "#feb13b"],
    "Banana": ["#d1ff79", "#ff2b3a", "#971c30", "#51132a"],
    "Marine": ["#f8f5e3", "#002746", "#174e79", "#e57525", "#614735"],
    "Baobab": ["#86ac81", "#ffd2b2", "#d25a59", "#6a2e4d"],
    "Jeans": ["#407ba5", "#253447", "#e0634d", "#7e462b", "#fcfcfc"],
    "Blue": ["#edeef3", "#0000c0", "#333ea2", "#93b3ed", "#000095", "#155cbd", "#7db3fb", "#7db3fb"],
    "Flower": ["#fac6d0", "#ef8c8d", "#fdf494", "#709255", "#416d05"],
    "Tired": ["#a76b81", "#e9dde0", "#a093a6", "#67687e", "#4a535f"],
    "Sumer": ["#c8cf7f", "#eee099", "#f7efc2", "#fcbf89", "#000000"],
    "Beach": ["#fffacc", "#ffebba", "#ffd0b5", "#ffb2b2", "#6dc9c9", "#a5d8d8"],
    "Apple": ["#c59570", "#d8b39a", "#efefc3", "#dee58a", "#c3e54a"],
    "Underwater": ["#d6f6b4", "#a9e899", "#44a682", "#467d7f", "#376d6d"],
    "Sunset": ["#ffe054", "#febc68", "#f18f86", "#c56d9d", "#9967a4"], 
    "Soft": ["#e1f4fa", "#c8edff", "#d2cffe", "#f0cafd", "#fdd8ea"], 
    "Queen": ["#c01820", "#d83840", "#f05860", "#981060", "#700040"], 
    "Decay": ["#504040", "#908088", "#b0a8b8", "#d8d0e0", "#c04850", "#803840"], 
    "Used": ["#b1b26a", "#8d824a", "#63382d", "#d28455", "#f4d5a0"], "Purple": ["#6180d6", "#82a4fd", "#f4aff1", "#bc7cdb", "#484374"], "SnowWhite": ["#681514", "#921911", "#c1e7f5", "#72caf1", "#49b7ec"],
    "Sahara": ["#8b2a0e", "#993513", "#bb5026", "#f88339", "#a6c6f5"], 
    "Island": ["#5b2a1f", "#bd7050", "#a5a570", "#c1ddf1", "#8bb2db"],
    "StaryNight": ["#0f1724", "#12232d", "#222a37", "#394144", "#564d42"], 
    "Playfull": ["#bb95b7", "#e4a4b0", "#fcc1ae", "#f2eeae", "#6fc6ca"],
    "Pirate": ["#4e1e22", "#902d23", "#0d3f2d", "#051e33", "#000524"], 
    "Vegas": ["#271a22", "#34222d", "#9f9cd9", "#7673c2", "#26223d"],
    "ModernArt": ["#364b6f", "#3e658e", "#b7ac9f", "#5a4e31", "#443f28"], 
    "Sweden": ["#25778d", "#7dacc1", "#d69037", "#bd7632", "#8e512a"], 
    "California": ["#38355b", "#70608b", "#d8678c", "#f37571", "#e99687"], 
    "Moon": ["#051e3f", "#193056", "#445273", "#9a899a", "#b9a6aa"], 
    "Nut": ["#7c6743", "#ffea7c", "#d0db6b", "#a8bc58", "#88964e"], 
    "Neon": ["#e032a0", "#ee2afd", "#ba3afc", "#7c62fc", "#5d8afc", "#50abfd"], 
    "Atmospher": ["#f88fe8", "#fe75e1", "#ea31cd", "#701ea4", "#3d2190"],
    "Ice": ["#008be3", "#3ba7f5", "#70bffe", "#9cd1ff", "#bae1fe"], 
    "Emerald": ["#061b11", "#022601", "#265902", "#65a603", "#97d602"],
    "Geometric": ["#4a4759", "#d99484", "#f2bc8d", "#d9cc8b", "#0e0e0e"], 
    "Road": ["#aba9d9", "#d2b7bc", "#94b7b8", "#44a69c", "#188c77"], 
    "Blueberry": ["#1a243f", "#3c5880", "#7b97be", "#836871", "#7b3438"], 
    "Pride": ["#750787", "#004dff", "#008026", "#ffed00", "#ff8c00", "#e40303"], 
    "YourName": ["#0c1030", "#085888", "#88cde4", "#38478c", "#5d2c74", "#8e5d87", "#3b3c81"], 
    "Urban": ["#70c3c7", "#7db3cd", "#608099", "#728d7c", "#b4adcc", "#dcc2a7"], 
    "Estrella": ["#3a3462", "#4d577a", "#6e1e53", "#e8ab96", "#f3c3b9", "#efa7bb"],
    "RPG": ["#7cb4d5", "#7fc8d1", "#294644", "#507446", "#d3bb8b", "#924440"], 
    "Firewatch": ["#ffd5a3", "#fd9d33", "#b44633", "#6a202d", "#46152a", "#311126"],
    "GrandBudapest": ["#76504f", "#e29988", "#eb7d80", "#b98e87", "#514859", "#527a86"], 
    "Sand": ["#5f6a91", "#d76a54", "#fceebc", "#3b3f5a", "#f6c749"], 
    "Venon": ["#684266", "#442f56", "#333559", "#2a354c", "#434d60"], 
    "Ceramique": ["#112625", "#445c56", "#889f8a", "#ccd6d1", "#d5cfc1", "#ece2de"], 
    "Cherry": ["#ba2f27", "#441825", "#6a89b1", "#c5c4d6", "#ddd7e3", "#eae3e4"], 
    "Wild": ["#687db6", "#46425d", "#515648", "#d58659", "#f2c285", "#e1ccde"],
    "Towel": ["#d1eaaf", "#ced071", "#ced071", "#bc92a2", "#d8c3d1", "#c5c5c6"],
    "Lavender": ["#f6dbe0", "#e7eee3", "#d3d3f2", "#7a7ad8", "#564fa7", "#705e8f"],
    "Cacti": ["#ece2de", "#d5cfc1", "#ccd6d1", "#889f8a", "#445c56", "#112625"],
    "Succulent": ["#d58568", "#dce0c3", "#bcd0b3", "#9cb6a8", "#577160", "#30402f"],
    "Violet": ["#f3f1ef", "#dae2f8", "#7ab799", "#324942", "#593071", "#ca74bb"],
    "Dust": ["#687db6", "#46425d", "#515648", "#d58659", "#f2c285", "#e1ccde"], 
    "Limon": ["#87b083", "#2e503d", "#dfac5a", "#ebd175", "#f0eea5", "#f1f9cf"], 
    "Coton": ["#98b686", "#786a73", "#ab80b0", "#c6b0e4", "#dfcef7", "#f1f0ec"],
    "Honey": ["#f7f6f3", "#efece6", "#eec791", "#cb7046", "#624f48", "#bca994"],
    "Holliday": ["#b2eeee", "#7ed6da", "#3682bc", "#243c5c", "#324e51", "#b8ac7f"],
    "Surreal": ["#7567b8", "#142235", "#31448f", "#468598", "#79c5c3", "#c5f7de"],
    "Sweat": ["#b9d4f5", "#4e4689", "#d24b63", "#f4b698", "#eae69e", "#e4e2e2"],
    "Signal": ["#0a1d3b", "#132f56", "#e5376a", "#f87b9b", "#f2f9ff"],
    "Sugar": ["#f1e3d2", "#fe97be", "#e65b51", "#2a5f9e", "#6fb882"],
    "SummerSky": ["#3062b8", "#589fc0", "#3a7ec9", "#78c5db", "#e2ded9", "#dcdcdc"],
    "Pico8": ["#000000", "#1D2B53", "#7E2553", "#008751", "#AB5236", "#5F574F", "#C2C3C7", "#FFF1E8", "#FF004D", "#FFA300", "#FFEC27", "#00E436", "#29ADFF", "#83769C", "#FFCCAA", "#FF77A8"],
    "Endesga": ["#be4a2f", "#d77643", "#ead4aa", "#e4a672", "#b86f50", "#733e39", "#3e2731", "#a22633", "#e43b44", "#f77622", "#feae34", "#fee761", "#63c74d", "#3e8948", "#265c42", "#193c3e", "#124e89", "#0099db", "#2ce8f5", "#ffffff", "#c0cbdc", "#8b9bb4", "#5a6988", "#3a4466", "#262b44", "#181425", "#ff0044", "#68386c", "#b55088", "#f6757a", "#e8b796", "#c28569"]
}

export default Palettes;