import { MdBuild, MdEdit } from "preact-icons/md";
import Canvas from '../Canvas';
import EditPanel from "../EditPanel";
import ProjectPanel from '../ProjectPanel';
import TabSystem, { TabButton, TabContent } from '../Tab';
import style from './style.css';
import FrameSlider from "../FrameSlider";

export function MainPanel({ commands, project, editor }) {
	return (

		<div className={style.tools}>
			<FrameSlider commands={commands} project={project} editor={editor} />
			<div className={style.tabs}>
				<TabSystem defaultTab="info">
					<div className={style.tabButtons}>
						<TabButton tabName="info" classSelected={style.selected}><MdBuild /> Info</TabButton>
						<TabButton tabName="edit" classSelected={style.selected}><MdEdit /> Edit</TabButton>
					</div>
					<div className={style.tabContents}>
						<TabContent tabName="info">
							<ProjectPanel commands={commands} project={project} editor={editor} />
						</TabContent>
						<TabContent tabName="edit">
							<EditPanel commands={commands} project={project} editor={editor} />
						</TabContent>
					</div>
				</TabSystem>
			</div>

		</div>



	);
}



export default MainPanel;